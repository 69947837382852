<template>
  <div>
    <van-cell-group>
      <van-field center v-for="(item,index) in dataList"  type="digit" v-model="item.value" :label="$route.params.title + ' '+ (index + 1)" :key="index" :placeholder="$t('请输入')" >
        <template #button>
          <van-button @click="minusHandle(item,index)" icon="minus"  type="danger" size="mini"></van-button>
        </template>
      </van-field>

      <van-cell v-if="this.$route.params.maxRows == 0 || dataList.length < this.$route.params.maxRows" title="">
        <!-- 使用 right-icon 插槽来自定义右侧图标 -->
        <template #right-icon>
          <van-button  icon="plus" @click="plusHandle" type="primary" size="mini"></van-button>
        </template>
      </van-cell>
    </van-cell-group>
    <div class="van-cell-div-labelc">
      {{this.$route.params.label}}
    </div>
    <div class="cell-div">
      <van-row  gutter="20">
        <van-col span="8"><van-button plain type="danger" @click="back" round block>返 回</van-button></van-col>
        <van-col span="16"><van-button :loading="loading" round block type="info" native-type="submit" @click="submit">{{$t('确认修改')}}</van-button></van-col>
      </van-row>


    </div>
  </div>
</template>

<script>
import {Col, Row, Field, CellGroup, Button, Cell, Notify} from 'vant';
import {updateSetting} from "@/api/devicesetting";
import {getImei} from "@/util/session-storage-utils";
export default {
  components:{
      [Col.name]:Col,
      [Row.name]:Row,
      [Field.name]:Field,
      [CellGroup.name]:CellGroup,
      [Cell.name]:Cell,
      [Button.name]:Button
  },
  name: "index",
  data(){
    return {
      dataList:[],
      loading:false
    }
  },
  methods:{
    minusHandle(item,index){
      let dataList = this.dataList;
      dataList.splice(index,1);
      this.dataList = dataList;
    },
    plusHandle(){
      let dataList = this.dataList;
      dataList.push({value:""})
      this.dataList = dataList;
    },
    submit(){
      let req = {};
      let data = "";
      for (let i = 0; i < this.dataList.length; i++) {
        if(i != 0){
          data += "\n";
        }
        data +=this.dataList[i].value

      }
      req[this.$route.params.id] = data
      this.isChange = true;
      this.loading = true;
      updateSetting(getImei(),req)
          .then(() => {
                Notify({ type: 'success', message: this.$t('数据更新成功'),duration:500 })
                this.$router.go(-1);
              }
          ).finally(() => this.loading = false)
    },
    back(){
      window.history.back();
    },
  },
  created() {
    document.title = this.$t("设备设置");
    let data = this.$route.params.data;
    let arrays = [];
    if(data){
      arrays = data.split("\n");
    }
    let dataList = [];
    for (let i = 0; i < arrays.length; i++) {
      dataList.push({value:arrays[i]})
    }
    this.dataList = dataList;

  },

}
</script>

<style scoped src="../../../../src/css/setting/global.css"/>
